import React from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';

import { PersonalInformation, PersonalInformationProps } from 'types/personal-information';
import { PERSONAL_INFORMATION_SCHEMA } from 'schema/personal-information';
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';

const PersonalInformationForm = ({ onSubmit, style, defaultValue }: PersonalInformationProps) => {
    const { t } = useTranslation();
    const formName = 'PersonalInformationForm';

    const handleSubmit = (values: Partial<PersonalInformation>, { setSubmitting }: { setSubmitting: Function }) => {
        const submissionCallback = () => {
            setSubmitting(false);
        };

        onSubmit(values as PersonalInformation, submissionCallback);
    };

    return (
        <Container fluid style={style} data-ga-location="Personal Info">
            <Row>
                <Col className="d-flex flex-column">
                    <Formik<Partial<PersonalInformation>>
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                        validationSchema={PERSONAL_INFORMATION_SCHEMA(t('countryCode'))}
                        initialValues={defaultValue}
                    >
                        {(formik: any) => (
                            <Form
                                id="personal-information-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Text
                                            label={t('pages.profile.personalInformation.labels.firstName')}
                                            name="firstName"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.firstName
                                                    ? t('pages.profile.personalInformation.errors.firstName')
                                                    : undefined
                                            }
                                            touched={formik.touched.firstName}
                                            value={formik.values?.firstName}
                                            defaultValue={formik.initialValues?.firstName}
                                            readOnly={true}
                                        />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Text
                                            label={t('pages.profile.personalInformation.labels.lastName')}
                                            name="lastName"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.lastName
                                                    ? t('pages.profile.personalInformation.errors.lastName')
                                                    : undefined
                                            }
                                            touched={formik.touched.lastName}
                                            value={formik.values?.lastName}
                                            defaultValue={formik.initialValues?.lastName}
                                            readOnly={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Text
                                            label={t('pages.profile.personalInformation.labels.dateOfBirth')}
                                            name="dateOfBirth"
                                            maxLength={255}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.dateOfBirth
                                                    ? t('pages.profile.personalInformation.errors.dateOfBirth')
                                                    : undefined
                                            }
                                            touched={formik.touched.dateOfBirth}
                                            value={formik.values?.dateOfBirth}
                                            defaultValue={formik.initialValues?.dateOfBirth}
                                            autocomplete="off"
                                            readOnly={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Text
                                            label={t('pages.profile.personalInformation.labels.email')}
                                            name="email"
                                            maxLength={255}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.email
                                                    ? t('pages.profile.personalInformation.errors.email')
                                                    : undefined
                                            }
                                            touched={formik.touched.email}
                                            value={formik.values?.email}
                                            defaultValue={formik.initialValues?.email}
                                            autocomplete="off"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PhoneNumberText
                                            name="phoneNumber"
                                            label={t('pages.profile.personalInformation.labels.phoneNumber')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.phoneNumber
                                                    ? t('prescriberDetailsForm.errors.phoneNumber')
                                                    : undefined
                                            }
                                            touched={formik.touched.phoneNumber}
                                            value={formik.values?.phoneNumber}
                                            defaultValue={formik.values?.phoneNumber}
                                            countryCode={t(`countryCode`)}
                                            autocomplete="off"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            async
                                            className="sm-full"
                                            label={t('pages.profile.personalInformation.labels.update')}
                                            variant="primary"
                                            type="submit"
                                            disabled={!formik.dirty || formik.isSubmitting}
                                            dataGAFormName={formName}
                                            isBusy={formik.isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default PersonalInformationForm;
