import React from 'react';
import { graphql } from 'gatsby';
import PersonalInformationForm from './intra-page-items/_personal-information.item';
import { PersonalInformation } from 'types/personal-information';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { accountProfileSelector } from 'state/account/account.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { accountUpdateProfileRoutine } from 'state/account/account.routines';
import produce from 'immer';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from './intra-page-items/_profile-update-modal.item';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';

const PersonalInfo = () => {
    const { t } = useTranslation();
    const profileObject = useSelector(accountProfileSelector);
    const dispatch = useDispatch();

    const handleSubmit = (personalInformation: PersonalInformation, submissionCallback: Function) => {
        if (profileObject) {
            const updatedProfilePayload = produce(profileObject, (draftProfileObject) => {
                draftProfileObject.patientEmailAddress = personalInformation.email;
                const phone = parsePhoneNumberFromString(personalInformation.phoneNumber, 'US');
                if (phone) {
                    draftProfileObject.phones[0].phoneAreaCode = phone.nationalNumber.substr(0, 3);
                    draftProfileObject.phones[0].phoneNumber = phone.nationalNumber.substr(3);
                }
            });
            dispatch(
                accountUpdateProfileRoutine({
                    ...updatedProfilePayload,
                    onSuccess: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <UpdateProfileModalContent area={t('modals.updateProfile.areas.profile')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'PersonalInfoUpdateProfile'
                                    }
                                ]
                            })
                        );
                        submissionCallback();
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                size: 'lg',
                                type: 'danger',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.profile')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'PersonalInfoUpdateProfileError'
                                    }
                                ]
                            })
                        );
                        submissionCallback();
                    }
                })
            );
        }
    };

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.personalInformation.title`)}
            heading={t(`pages.profile.personalInformation.heading`)}
        >
            <PersonalInformationForm
                defaultValue={{
                    firstName: profileObject?.patientFirstName ? profileObject?.patientFirstName : '',
                    lastName: profileObject?.patientLastName ? profileObject?.patientLastName : '',
                    email: profileObject?.patientEmailAddress ? profileObject?.patientEmailAddress : '',
                    dateOfBirth: profileObject?.patientDob ? profileObject?.patientDob : '',
                    phoneNumber: profileObject?.phones
                        ? `1${profileObject?.phones[0].phoneAreaCode}${profileObject?.phones[0].phoneNumber}`
                        : ''
                }}
                onSubmit={handleSubmit}
            />
        </ProfileLayout>
    );
};

export default PersonalInfo;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
