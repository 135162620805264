import * as yup from 'yup';

import { validatePhoneNumber } from './validation/phone-number';

export const PERSONAL_INFORMATION_SCHEMA = (countryCode: 'US' | 'CA') =>
    yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        dateOfBirth: yup.string().required(),
        email: yup.string().required(),
        phoneNumber: yup
            .string()
            .test(
                'Phone Number validation',
                (value: string | undefined, context: yup.TestContext<Record<string, any>>) =>
                    validatePhoneNumber(value, context, countryCode)
            )
    });
